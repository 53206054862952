body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.token-mutable,
.token-immutable {
  background-color: rgba(204, 204, 255, 0.8);
  padding: 2px 5px;
  border-radius: 5px;
}

.token-mutable:hover,
.token-immutable:hover {
  background-color: rgba(204, 204, 255, 1.0);
  cursor: pointer;
}

a.token-mutable,
a.token-immutable {
  text-decoration: none;
  color: inherit
}

.DraftEditor-editorContainer {
  margin-top: 1em
}

/* .App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 5vh;
  text-align: center;
}

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editor-class {
  background-color:lightgray;
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
} */

.stylizedBlockquote {
  color: #999;
  font-family: 'Hoefler Text', Georgia, serif;
  font-style: italic;
  /*text-align: center;*/

  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
  background-color: ghostwhite;
  border: 1px solid whitesmoke;

  padding: 5px;
  
}

.stylizedBlockquote:before {
  content: "\"";
  position: absolute;
  font-size: 2rem;
  margin: -1.2rem;
}

.corroboration-content .stylizedBlockquote {
  color: #888;
  background: rgba(100,0,123,0.05);
  border: 1px solid rgba(100,0,123,0.1);
  border-radius: 5px;
}

.corroborations-enter {
  opacity: 0;
}
.corroborations-enter-active {
  opacity: 1;
  transition: opacity 2000ms;
}
.corroborations-exit {
  opacity: 1;
}
.corroborations-exit-active {
  opacity: 0;
  transition: opacity 2000ms;
}



/* reset & common styles */
header h1, header div, header a{
  color: white;
  letter-spacing: 2px;
  list-style-type: none;
  text-decoration: none;
}

h1, h2, h3 {
  color: white;
}

.DraftEditor-root h1,
.DraftEditor-root h2,
.DraftEditor-root h3,
.corroboration-content h1,
.corroboration-content h2,
.corroboration-content h3 {
  color: var(--bs-body-color)
}

.awlhfjh .b1qfpj3o:nth-child(even) {
  display: none
}

body{
  background: rgb(100,0,123);
  background: radial-gradient(circle, rgba(100,0,123,1) 0%, rgba(62,20,86,1) 100%);
}
.container{
  /*max-width: 300px;*/
  margin: 100px auto 40px;
}
/* .container h3{
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(255,255,255,0.2);
} */
/* .container li{
  padding: 10px;
  cursor: pointer;
} */

/* lists */
/* li span.active{
  font-weight: bold;
}
li span.active::before{
  content: '>';
  position: relative;
  top: -2px;
  margin-right: 6px;
  transform: scale(0.8, 1);
  display: inline-block;
} */

/* pages */
.home h2{
  font-size: 2em;
  margin-bottom: 30px;
}
.home.container, 
.order.container{
  text-align: center;
  max-width: 800px;
}
.home button{
  color: white;
  padding: 15px 30px;
  font-size: 1.6em;
  border: 3px solid white;
  margin: 30px 20px;
}

/* header */
header{
  /*display: flex;*/
  padding: 40px;
  /*align-items: center;*/
}
header .logo a:hover {
  text-decoration: none;
  color: white;
}
header .title{
  display: flex;
  flex-grow: 1;
  margin-left: 20px;
  font-size: 0.6em;
  align-items: flex-end;
}
header h1{
  /*border-bottom: 1px solid rgba(255,255,255,0.2);*/
  padding-bottom: 10px;
  letter-spacing: 0.2rem;
}

#burger-menu-dropdown::after {
    display:none
}

@media only screen and (max-width: 600px) { 
  header { padding: 20px; }
  header h1 { font-size: 2rem }
  header .logo { max-width: 50px; padding-bottom: 10px } 
  .nav-wrapper { display: none; }
  .burger-wrapper { display: flex; flex: 1 1 auto }
  .home-container { margin-top: -120px!important }
  .button-start { border-radius: 5px; font-size: 0.8rem; }
  .button-start::after { display: none }
  .post-header { padding: 2em 6% 0.5em 6% }
  .post-content { padding: 1em 5% }
  .corroboration { padding: 1em 3% }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  header { padding: 20px; }
  header h1 { font-size: 2rem }
  header .logo { max-width: 60px; padding-bottom: 10px }
  .nav-wrapper { display: none; }
  .burger-wrapper { display: flex; flex: 1 1 auto }
  .home-container { margin-top: -120px!important }
  .button-start { border-radius: 5px; font-size: 0.8rem; }
  .button-start::after { display: none }
  .post-header { padding: 2em 3% 0.5em 3% }
  .post-content { padding: 1em 5% }
  .corroboration { padding: 1em 3% }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) { 
  header { padding: 30px }
  header h1 { font-size: 2.5rem }
  header .logo { max-width: 80px; padding-bottom: 5px } 
  .nav-wrapper { display: flex; flex: 1 1 auto }
  .burger-wrapper { display: none }
  .home-container { margin-top: -130px!important }
  .button-start { border-radius: 5px 0 0 5px; font-size: 1rem; }
  .button-start::after { display: block }
  .post-header { padding: 2.5em 6% 0.5em 6% }
  .post-content { padding: 1.5em 8% }
  .corroboration { padding: 1.5em 6% }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  header h1 { font-size: 3rem }
  header .logo { max-width: 90px; padding-bottom: 5px }
  .post-header { padding: 3em 15% 0.5em 15% }
  .post-content { padding: 1.5em 16% }
  .corroboration { padding: 1.5em 15% }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) { 
  header h1 { font-size: 3.5rem }
  header .logo { max-width: 100px; padding-bottom: 0px } 
}


nav {
  /*display: flex;
  align-items: center;*/
  /*max-width: 1200px;*/
  margin: 0 auto;
  border-bottom: 1px solid rgba(255,255,255,0.2);
}
nav .links {
  margin-left: auto;
  display: flex;
  justify-content: right;
  align-items: center;
}
nav .links a,
nav .links button {
  margin-left: 16px;
  font-size: 14px;
  display: inline-block;
}

nav span {
  font-size: 14px;
  display: inline-block;
  margin-left: 16px;
  padding-left: 16px;
  padding-right: 10px;
  border-left: 1px solid #eeeeee;
}

.home-container {
  background-size: cover;
  padding-bottom: 2em;
  /*height: calc(100vh - 140px);*/
  width: 100%;
  margin-top: -130px
}

.home-container h1 {
  font-size: 3em;
  text-align: right;
  padding-bottom: 0.5em;
  /* font-weight: 'bold';
  text-shadow: 0px 1px  #000000, 1px 0px 2px  #ffffff; */
}

.home-container-p {
  text-align: justify;
  color: #ffffff
}

.btn-header-user-options {
  font-weight: 600;
  color: #ffe484;
  border-color: #ffe484;
}

.btn-header-user-options:hover, .btn-header-user-options:active {
  color: #2a2730;
  background-color: #ffe484;
  border-color: #ffe484;
}

.section-header {
  text-align: center;
  /*color: #ffffff;*/
}

.section-header h1 {
  padding-top: 0.5em;
  margin-bottom: 0;
  font-size: 2.5em;
}

.post-header {
  background: white;
}

.post-header-content {
  background: white;
  border-bottom: 1px solid rgba(100,0,123,0.3);
  color: rgba(100,0,123,0.5);
}

.post-content {
  background: white;
}

/*svg#bigTriangleColor {
  pointer-events: none;
}

#bigTriangleColor path {
  fill: #ffffff;
  stroke: #ffffff;
  stroke-width: 2;
}*/

.corroborations {
  background: white;
  flex:1;
  padding-top: 1em;
  padding-bottom: 2em
}

.corroboration {
  margin: auto;
  /*padding: 2em 15%*/
}

.corroboration img {
  max-width: 80%;
}

.corroboration-content {
  background: rgba(100,0,123, 0.1);
  overflow: inherit;
  border-radius: 15px;
  padding: 10px 20px;
}

.corroboration-header {
  margin-left: 20px;
  margin-bottom: 10px;
  margin-top: -20px;
  line-height: 1em;
  display: block;
  text-transform: uppercase;
  position: relative
}

.corroboration-header-link {
  border-radius: 5px 5px 0 0;
  color: white;
  text-decoration: none;
  font-size: 15px;
  background: rgb(100,0,123);
  display: inline-block;
  padding: 6px 7px;
  cursor: pointer;
}

.corroboration-header-link.unlinked {
  cursor: unset;
  pointer-events: none;
}

.corroboration-header-link:not(.unlinked):hover {
  opacity: 0.8;
}

.corroboration-header-link:not(.unlinked) div::after {
  content: '\25b2';
  margin-left: 1em;
  margin-right: 0.2em;
  vertical-align: top;
  font-size: x-small
}

.corroboration-header-link span {
  display: inline-block;
  overflow:hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 20em;
}

.button-start {
	/*margin: 2rem auto;*/
	text-align: center;
	/*width: 13rem;*/
	padding: 0.01rem 1em;
	background: var(--bs-warning);
	color: #FFFFFF;
  position: relative;
  /*border-radius: 5px 0 0 5px;*/
  cursor: pointer;
}

.button-start:hover {
  /*background: #ffe484;*/
  filter: brightness(110%);
}

.button-start p {
	vertical-align: bottom;
	/*font-family: "Lato";*/
	/*font-size: 1.75rem;*/
  text-transform: uppercase;
  letter-spacing: 0.15em;
  line-height: 2.50em;
  margin-bottom: 0;
}
.button-start::after {
  content: "";
  /*display: block;*/
  position: absolute;
  border-color: transparent var(--bs-warning);
  border-style: solid;
  border-width: 20px 0px 20px 20px;
  top: 0;
  left: 100%;
}

.vertical-center {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

/* .hero {
  position: relative; 
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero::before {    
  content: "";
  background-image: url('https://placekitten.com/1200/800');
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.75;
}

.hero h2 {
  position: relative;
  color: #ffffff;  
  font-size: 14rem;
  line-height: 0.9;
} */